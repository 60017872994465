export const formatPhoneNumber = (
  phoneNumber: string,
  delimiter: string = " "
) => {
  if (phoneNumber.length !== 10) return phoneNumber;
  return (
    phoneNumber.slice(0, 3) +
    delimiter +
    phoneNumber.slice(3, 6) +
    delimiter +
    phoneNumber.slice(6, 10)
  );
};

export function maskPhoneNumber(phoneNumberString: string) {
  var x = phoneNumberString
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)!;
  return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
}
