import { ToastContext } from "@root/routes/ToastProvider";
import { useContext, useRef } from "react";

type Options = {
  persistOnPageChange?: boolean;
  overwriteToast?: boolean;
};

export default function useToast() {
  const toastContext = useContext(ToastContext);

  const toastRef = useRef(toastContext.theToast);

  const toast = {
    success: (message: string, options?: Options) =>
      toastContext.toast(
        {
          message,
          variant: "success",
          persistOnPageChange: defaultVal(options?.persistOnPageChange, false),
          overwriteToast: defaultVal(options?.overwriteToast, true),
          visible: true,
        },
        toastRef.current.visible
      ),
    error: (message: string, options?: Options) =>
      toastContext.toast(
        {
          message,
          variant: "error",
          persistOnPageChange: defaultVal(options?.persistOnPageChange, false),
          overwriteToast: defaultVal(options?.overwriteToast, true),
          visible: true,
        },
        toastRef.current.visible
      ),
    info: (message: string, options?: Options) =>
      toastContext.toast(
        {
          message,
          variant: "info",
          persistOnPageChange: defaultVal(options?.persistOnPageChange, false),
          overwriteToast: defaultVal(options?.overwriteToast, true),
          visible: true,
        },
        toastRef.current.visible
      ),
    clearError: () => {
      if (toastContext.theToast.variant === "error") {
        toastContext.toast(
          {
            message: "",
            variant: "info",
            persistOnPageChange: false,
            overwriteToast: true,
            visible: false,
          },
          toastRef.current.visible
        );
      }
    },
    theToast: toastContext.theToast,
  };

  return toast;
}

function defaultVal(val: boolean | undefined, d: boolean) {
  return val === undefined ? d : val;
}
