export * from "./setRequestHeader";
export * from "./phone";

export function getAppVersionString() {
  return "Mobile Site v1.1.77";
}

export function stripWhitespace(value: string) {
  return value.replace(/ /g, "");
}
