import { useEffect, useState } from "react";
import Button from "@root/components/Button";
import Environment from "@root/configs/env";
import axios, { AxiosError } from "axios";
import useToast from "@root/hooks/useToast";
import { useNavigate } from "react-router";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import FormErrorText from "@root/components/FormErrorText";
import useSWR from "swr";
import ToastBox from "@root/components/ToastBox";
import { getUserProfile } from "@root/utils/api";

type Values = {
  checked: boolean;
};

const validationSchema = Yup.object().shape({
  checked: Yup.bool().oneOf([true], "This field is required."),
});

export default function DeleteAccountPage() {
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  const { data: user } = useSWR("user", getUserProfile, {
    suspense: true,
    keepPreviousData: false,
  });

  useEffect(() => {
    if (!user.active)
      toast.success(
        "Request received. We will process it as soon as possible."
      );
  });

  return (
    <Formik
      initialValues={{ checked: false }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={async (_values: Values) => {
        setLoading(true);
        setIsButtonDisabled(true);
        axios
          .delete(`${Environment.VITE_SERVICE_USER_MANAGEMENT_URL}/profile`)
          .then(() => {
            toast.success(
              "Request received. We will process it as soon as possible."
            );
          })
          .catch((err: AxiosError) => {
            toast.error(err.response?.data as string);
            setIsButtonDisabled(false);
          })
          .finally(() => setLoading(false));
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form
          className="flex flex-col gap-y-10 items-center justify-center"
          onSubmit={handleSubmit}
        >
          <ToastBox />

          <h2 className="pb-[35px] pt-[60px] w-full text-center text-white font-extrabold text-2xl md:text-4xl">
            Delete Account
          </h2>

          <p className="text-white text-center">
            Your account will be permanently deleted, all account data will be
            removed. You will have to use the signup feature to re-create the
            account again to use the Nxu Charging service.
          </p>

          <div className="flex items-center gap-x-5 justify-center">
            <div className="flex items-center">
              <input
                id="helper-checkbox"
                aria-describedby="helper-checkbox-text"
                type="checkbox"
                onChange={(_e) => setFieldValue("checked", !values.checked)}
                checked={values.checked}
                className="w-5 h-5 bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-nxu-charging-gold accent-nxu-charging-gold"
              />
            </div>
            <label htmlFor="helper-checkbox" className="text-white">
              I Understand
            </label>
          </div>
          <ErrorMessage name="checked" component={FormErrorText} />

          <div className="flex flex-col gap-y-5 items-center">
            <Button
              className="bg-nxu-charging-red hover:bg-nxu-charging-red hover:brightness-50 disabled:bg-nxu-charging-red disabled:brightness-50"
              type="submit"
              loading={loading}
              disabled={isButtonDisabled || !user.active}
            >
              Delete Account
            </Button>

            <Button onClick={() => navigate("/profile")}>Cancel</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
