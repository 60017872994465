import { useState } from "react";
import Button from "@root/components/Button";
import { ErrorMessage, Field, Form, Formik } from "formik";
import axios, { AxiosError } from "axios";
import Environment from "@root/configs/env";
import useToast from "@root/hooks/useToast";
import * as Yup from "yup";
import FormErrorText from "@root/components/FormErrorText";
import ToastBox from "@root/components/ToastBox";

export function maskPhoneNumber(phoneNumberString: string) {
  var x = phoneNumberString
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)!;
  return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
}

const topics = [
  "Product Question",
  "Account Issue",
  "Charging Issue",
  "Other",
] as const;

type Values = {
  phoneNumber: string;
  description: string;
  topic: (typeof topics)[number];
};

export default function CustomerSupportPage() {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(
        /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Phone number is not valid"
      )
      .required("Required"),
    topic: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div>
        <ToastBox />
      </div>

      <h2 className="pb-[35px] pt-[60px] w-full text-center text-white font-extrabold text-2xl md:text-4xl">
        Nxu Customer Support
      </h2>

      <Formik
        initialValues={{ phoneNumber: "", description: "", topic: "Other" }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={async (values: Values) => {
          const { phoneNumber, topic, description } = values;
          setLoading(true);
          setButtonDisabled(true);

          axios
            .post(`${Environment.VITE_SERVICE_USER_MANAGEMENT_URL}/support`, {
              phoneNumber,
              topic,
              description,
            })
            .then(() => {
              toast.success(
                "Thanks for submitting the request. We will be in touch soon!"
              );
            })
            .catch((err: AxiosError) => {
              toast.error(err.response?.data as string);
              setButtonDisabled(false);
            })
            .finally(() => setLoading(false));
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form
            className="flex flex-col w-[50%] h-[100%] gap-y-10"
            onSubmit={handleSubmit}
          >
            <div>
              <label className="block mb-2 text-sm font-medium text-white">
                Phone Number
              </label>
              <Field
                name="phoneNumber"
                className="h-[50px] px-5 bg-white rounded-[5px] placeholder-nxu-charging-placeholder placeholder:italic focus-visible:outline-none"
                onBlur={handleBlur}
                onChange={(e: any) => {
                  setFieldValue("phoneNumber", maskPhoneNumber(e.target.value));
                }}
                value={maskPhoneNumber(values.phoneNumber)}
                inputMode="numeric"
              />
              <p className="text-gray-200 text-xs italic">
                Please enter your phone number you use for your NXU charging
                account.
              </p>
              <ErrorMessage name="phoneNumber" component={FormErrorText} />
            </div>

            <div>
              <label className="block mb-2 text-sm font-medium text-white">
                How can we help?
              </label>
              <Field
                as="select"
                name="topic"
                className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                {topics.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Field>
              <p className="text-gray-200 text-xs italic">
                Please select a topic
              </p>
              <ErrorMessage name="topic" component={FormErrorText} />
            </div>

            <div>
              <label className="block mb-2 text-sm font-medium text-white">
                Description
              </label>
              <Field
                id="description"
                name="description"
                as="textarea"
                handleChange={handleChange}
                value={values.description}
                rows={4}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              />
              <p className="text-gray-200 text-xs italic">
                Please enter the details of your request
              </p>
              <ErrorMessage name="description" component={FormErrorText} />
            </div>

            <div className="self-start mb-3">
              <Button loading={loading} disabled={buttonDisabled} type="submit">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
