// @ts-nocheck
import {
  ToastContext,
  ToastMessage,
  ToastVariant,
} from "@root/routes/ToastProvider";

type Props = {
  expand?: boolean;
};

export default function ToastBox({ expand = true }: Props) {
  const colors = {
    success: "text-nxu-charging-green",
    error: "text-nxu-charging-red",
    info: "text-white",
  };

  return (
    <ToastContext.Consumer>
      {(value) => (
        <div className={`${expand ? "flex-[100%]" : "flex h-fit"} py-2`}>
          {value.theToast.visible &&
            isDisplayable(value.theToast as ToastMessage) && (
              <p
                className={`text-center text-[12px] ${
                  colors[value.theToast.variant as ToastVariant]
                }`}
              >
                {value.theToast.message || ""}
              </p>
            )}
        </div>
      )}
    </ToastContext.Consumer>
  );
}

function isDisplayable(theToast: ToastMessage) {
  return (
    theToast.message &&
    theToast.message !== "" &&
    theToast.message !== "none" &&
    theToast.variant
  );
}
