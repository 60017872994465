import {
  ReactNode,
  createContext,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";

export type ToastMessage = {
  message: string;
  variant: ToastVariant;
  persistOnPageChange: boolean;
  overwriteToast: boolean;
  visible: boolean;
};

export const ToastContext = createContext({
  toast: (_val: ToastMessage, _passedToast: boolean) => {},
  theToast: {
    message: "",
    variant: "",
    persistOnPageChange: false,
    overwriteToast: true,
    visible: false,
  },
});

export type ToastVariant = "success" | "error" | "info";

type Props = {
  children: ReactNode;
};

export default function ToastProvider({ children }: Props) {
  const [theToast, setTheToast] = useState<ToastMessage>({
    message: "",
    variant: "success",
    persistOnPageChange: false, // Control whether or not the toast is displayed after a page change.
    overwriteToast: true, // Control whether or not the previously displayed toast message will be overwritten
    visible: false,
  });

  const pathname = window.location.pathname;

  // Dismiss toast on every page change
  useLayoutEffect(() => {
    if (theToast.persistOnPageChange) {
      setTheToast((prev) => ({
        ...prev,
        persistOnPageChange: false,
        visible: true,
      }));
      return;
    }

    onDismiss();
  }, [pathname]);

  const toast = useCallback(
    (content: ToastMessage, passedToast: boolean) => {
      if (!content.overwriteToast && theToast.visible) return;

      setTheToast((prev) => ({ ...prev, ...content }));
    },
    [JSON.stringify(theToast)]
  );

  const onDismiss = () => {
    setTheToast((prev) => ({ ...prev, visible: false }));
  };

  return (
    <ToastContext.Provider value={{ toast, theToast }}>
      {children}
    </ToastContext.Provider>
  );
}
