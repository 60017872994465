import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ConfirmIcon } from '../assets/tickOutline.svg';

type Props = {
    title: string
    fields: FormikField[]
    onSubmit: (value: any) => void | Promise<any>
    isSubmitLoading: boolean
    onCancel?: (() => void) | null
    validateOnBlur?: boolean
    validateOnChange?: boolean
    confirmButtonText?: string
}

type FormikField = {
    name: string,
    type: React.HTMLInputTypeAttribute
    initialValue: string | number | boolean | undefined,
    yupValidation: Yup.Schema,
    editable: boolean,
    required: boolean
    transformValue?: (value: any) => any
    validation?: (value: any) => boolean,
    className?: string
}

export default function ManagedForm({
    title,
    fields,
    onSubmit,
    isSubmitLoading,
    onCancel = null,
    validateOnBlur = false,
    validateOnChange = false,
    confirmButtonText = "Done"
}: Props) {
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const navigate = useNavigate();

    const validationSchemaObject: { [key: string]: any } = {}
    fields.forEach((field) => { validationSchemaObject[field.name] = field.yupValidation })

    const initialValues: { [key: string]: any } = {}
    fields.forEach((field) => { initialValues[field.name] = field.initialValue })


    const inputStyle =
        "h-[50px] px-5 bg-white rounded-[5px] placeholder-nxu-charging-placeholder placeholder:italic focus-visible:outline-none";

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape(validationSchemaObject),
        validate: (values) => {
            let areFieldsValid = true;
            let areFieldsChanged = false;
            fields.forEach((field) => {
                if (field.required && !values[field.name]) areFieldsValid = false
                if (field.initialValue !== values[field.name]) areFieldsChanged = true
                if (field.validation) {
                    const validationResult = field.validation(values[field.name])
                    areFieldsValid = validationResult === false ? false : areFieldsValid
                }
            })
            setIsSubmitButtonDisabled(!(areFieldsValid && areFieldsChanged))

        },
        onSubmit: onSubmit,
        validateOnChange: validateOnChange,
        validateOnBlur: validateOnBlur,
    });

    return (
        <form
            className="flex flex-col items-center justify-center pb-7"
            onSubmit={formik.handleSubmit}
        >
            <div className="max-w-[350px] w-full flex flex-col justify-center gap-[30px]">
                <h2 className="py-[35px] w-full text-center text-white font-extrabold text-2xl md:text-4xl border-b border-b-nxu-charging-black">
                    {title}
                </h2>
                <div className="flex flex-col w-full gap-5 mb-5">
                    {fields.map((field, i) => {
                        const { values, errors, handleChange } = formik;
                        const { name, transformValue, editable, className } = field
                        const error = errors[name] as string
                        return (<div className='flex flex-col w-full gap-1'>
                            <input
                                key={`${name}-${i.toString()}`}
                                onChange={handleChange(name)}
                                value={transformValue
                                    ? transformValue(values[name])
                                    : values[name]
                                }
                                name={name}
                                readOnly={!editable}
                                className={`
                                ${inputStyle}
                                ${!editable ? 'italic bg-gray-300' : ''}
                                ${className ? className : ''}
                            `}
                            />
                            {error && (
                                <p className="text-red-500 text-xs italic">
                                    {error}
                                </p>
                            )}
                        </div>)
                    })}
                    <Button
                        iconLeft={<ConfirmIcon />}
                        onClick={() => {
                            formik.handleSubmit();
                            setIsSubmitButtonDisabled(true)
                        }}
                        loading={isSubmitLoading}
                        disabled={isSubmitButtonDisabled}>
                        {confirmButtonText}
                    </Button>
                    <Button
                        onClick={() => {
                            onCancel ? onCancel() : navigate('/profile')
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </form>
    )

}