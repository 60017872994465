import { FC, useState } from "react";
import useSWR from "swr";
import ManagedForm from "@root/components/ManagedForm";
import * as Yup from "yup";
import { formatPhoneNumber } from "@root/utils";
import { getUserProfile, updateUserProfile } from "@root/utils/api";

type Props = {};

const UpdateAccountInfo: FC = ({}: Props) => {
  const [submitting, setSubmitting] = useState(false);

  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const { data: user } = useSWR("user", getUserProfile, {
    suspense: true,
  });

  return (
    <div className="flex flex-col items-center">
      {errorMessage && (
        <p className="text-red-500 text-xs italic pt-2">{errorMessage}</p>
      )}
      {successMessage && (
        <p className="text-nxu-charging-green text-xs italic pt-2">
          {successMessage}
        </p>
      )}

      <ManagedForm
        title={"Update Account Information"}
        fields={[
          {
            name: "firstname",
            initialValue: user.firstName,
            yupValidation: Yup.string().required("Required"),
            editable: true,
            required: true,
            type: "text",
          },
          {
            name: "lastname",
            initialValue: user.lastName,
            yupValidation: Yup.string().required("Required"),
            editable: true,
            required: true,
            type: "text",
          },
          {
            name: "email",
            initialValue: user.email,
            yupValidation: Yup.string()
              .email()
              .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                "Provided email is not valid."
              )
              .required("Required"),
            validation: (value) => {
              return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
            },
            editable: true,
            required: true,
            type: "text",
          },
          {
            name: "phone",
            initialValue: user.phoneNumber,
            yupValidation: Yup.string().required("Required"),
            editable: false,
            required: true,
            transformValue: formatPhoneNumber,
            type: "text",
          },
        ]}
        onSubmit={function (_values: any): void | Promise<any> {
          const updatedUser = {
            ...user,
            firstName: _values.firstname,
            lastName: _values.lastname,
            email: _values.email,
          };
          updateUserProfile(updatedUser)
            .then(() => {
              setSuccessMessage("Profile updated.");
              setErrorMessage("");
            })
            .catch((err) => {
              if (err.response) setErrorMessage(err.response.data);
              else
                setErrorMessage(
                  "Error updating profile. Please try again later."
                );
              setSuccessMessage("");
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        isSubmitLoading={submitting}
        validateOnChange
        confirmButtonText="Confirm"
      />
    </div>
  );
};

export default UpdateAccountInfo;
