import axios, { AxiosError } from "axios";
import { getAppVersionString } from "@root/utils";
import Environment from "@root/configs/env";

export async function signup({
  email,
  firstName,
  lastName,
  phoneNumber,
  password,
}: any) {
  return axios.post(
    `${Environment.VITE_SERVICE_USER_MANAGEMENT_URL}/register-confirm-with-pin`,
    {
      email,
      firstName,
      lastName,
      phoneNumber,
      pinCode: password,
    }
  );
}

export const updateUserPassword = async (password: string) => {
  return axios
    .put(`${Environment.VITE_SERVICE_USER_MANAGEMENT_URL}/profile/password`, {
      password,
    })
    .then((res) => res.data);
};

export async function startCharge(stationId: string) {
  return axios
    .post<{ statusMessage: string; statusType: "success" | "error" | "none" }>(
      `${Environment.VITE_SERVICE_CHARGING_EVENT_URL}/start-charge`,
      {
        stationId,
        appVersion: getAppVersionString(),
      }
    )
    .then((res) => res.data);
}

export const findActiveSession = async () => {
  return axios
    .get(`${Environment.VITE_SERVICE_CHARGING_EVENT_URL}/active-session`)
    .then((res) => res.data)
    .catch((err: AxiosError) => {
      if (err.response?.status !== 200) return null;
      throw err;
    });
};

type Profile = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  billingPlanId: number;
  billingPlan: BillingPlan;
  vehicleCount: number;
  active: boolean;
};

export type BillingPlan = {
  id: number;
  billingPlan: string;
  active: boolean;
};

export async function getUserProfile(): Promise<Profile> {
  return axios
    .get(`${Environment.VITE_SERVICE_USER_MANAGEMENT_URL}/profile`)
    .then((res) => res.data);
}

export async function getBillingPlans(): Promise<BillingPlan[]> {
  return axios
    .get(`${Environment.VITE_SERVICE_USER_MANAGEMENT_URL}/billing-plans`)
    .then((res) => res.data);
}

export async function updateUserProfile(user: Profile): Promise<void> {
  return axios.put(
    `${Environment.VITE_SERVICE_USER_MANAGEMENT_URL}/profile`,
    user
  );
}

export async function updateUserBillingPlan(user: Profile): Promise<void> {
  return axios.put(
    `${Environment.VITE_SERVICE_USER_MANAGEMENT_URL}/profile/billing-plan`,
    user
  );
}

export async function setupSubscriptionPlan({
  vehicleCount,
}: {
  vehicleCount: number;
}): Promise<void> {
  return axios.put(
    `${Environment.VITE_SERVICE_USER_MANAGEMENT_URL}/subscriptions`,
    { vehicleCount }
  );
}
