import CustomerSupportPage from "@root/pages/Auth/CustomerSupportPage";
import DeleteAccountPage from "@root/pages/Auth/DeleteAccountPage";
import Landing from "@root/pages/Landing";
import React from "react";

const SigninPage = React.lazy(() => import("@root/pages/Auth/Signin"));
const SignupPage = React.lazy(() => import("@root/pages/Auth/Signup"));
const AuthForgotPasswordPage = React.lazy(
  () => import("../pages/Auth/ForgotPassword")
);
const AuthResetPasswordPage = React.lazy(
  () => import("../pages/Auth/ResetPassword")
);
const ChargingStationPage = React.lazy(
  () => import("../pages/Charging/Station")
);

export const publicRoutes = [
  {
    path: "/",
    component: Landing,
    role: "none",
  },
  {
    path: "/auth-sign-in",
    component: SigninPage,
    role: "none",
  },
  {
    path: "/auth-sign-up",
    component: SignupPage,
    role: "none",
  },
  {
    path: "/charging-station",
    component: ChargingStationPage,
    role: "none",
  },
  {
    path: "/forgot-password",
    component: AuthForgotPasswordPage,
    role: "none",
  },
  {
    path: "/set-password",
    component: AuthResetPasswordPage,
    role: "none",
  },
  {
    path: "/support",
    component: CustomerSupportPage,
    role: "none",
  },
  {
    path: "/delete-account",
    component: DeleteAccountPage,
    role: "none",
  },
];
